<template>

  <div>
    <p class="primary--text pt-4">{{ $t('PRODUCT.INFO') }}</p>
    <v-row class="mt-0">
      <v-col cols="8" class="pb-0 pt-0">
        <v-text-field
          v-model="title"
          @change="$emit('input', {title: $event})"
          :rules="[$rules.required, $rules.maxlength(80)]"
          :label="$t('PRODUCT.FIELD_TITLE')"
          :placeholder="$t('PRODUCT.FIELD_TITLE_PLACEHOLDER')"
          outlined
          dense
        />
      </v-col>
      <v-col cols="4" class="pb-0 pt-0">
        <v-select
          v-model="country_id"
          @change="$emit('input', {country_id: $event})"
          :items="countryItems"
          item-value="id"
          item-text="title"
          :rules="[$rules.required]"
          :label="$t('PRODUCT.FIELD_COUNTRY')"
          :placeholder="$t('PRODUCT.FIELD_COUNTRY_PLACEHOLDER')"
          outlined
          dense
        />
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="12" class="pb-0 pt-0">
        <v-textarea
          v-model="description"
          @change="$emit('input', {description: $event})"
          :rules="[$rules.required]"
          :label="$t('PRODUCT.FIELD_DESCRIPTION')"
          :placeholder="$t('PRODUCT.FIELD_DESCRIPTION_PLACEHOLDER')"
          outlined
          dense
        />
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="8" class="pb-0 pt-0">
        <v-text-field
          v-model="image_url"
          @change="$emit('input', {image_url: $event})"
          :rules="[$rules.required]"
          :label="$t('PRODUCT.FIELD_IMAGE_URL')"
          :placeholder="$t('PRODUCT.FIELD_IMAGE_URL_PLACEHOLDER')"
          outlined
          dense
        />
      </v-col>
    </v-row>

    <p class="primary--text pt-4">{{ $t('PRODUCT.BILL') }}</p>
    <v-row class="mt-0">
      <v-col cols="3" class="pb-0 pt-0">
        <v-text-field
          v-model="price"
          @change="$emit('input', {price: $event})"
          type="number"
          min="0"
          :rules="[$rules.required]"
          :label="$t('PRODUCT.FIELD_PRICE')"
          :placeholder="$t('PRODUCT.FIELD_PRICE_PLACEHOLDER')"
          outlined
          dense
        />
      </v-col>
      <v-col cols="3" class="pb-0 pt-0">
        <v-text-field
          v-model="discount"
          @change="$emit('input', {discount: $event})"
          required
          type="number"
          min="0"
          :label="$t('PRODUCT.FIELD_DISCOUNT')"
          :placeholder="$t('PRODUCT.FIELD_DISCOUNT_PLACEHOLDER')"
          outlined
          dense
        />
      </v-col>
    </v-row>

  </div>

</template>

<script>
export default {
  name:"FormProductCompactIntro",
  data: (_this) => ({
    countryItems: [],
    //
    title: undefined,
    description: undefined,
    image_url: undefined,
    price: undefined,
    discount: undefined,
    country_id: undefined,
  }),
  props: {
    values: {
      type: [Object, undefined],
      default: () => ({})
    }
  },
  watch: {
    values: {
      handler () {
        this.attribValues()
      },
      deep: true,
    },
  },
  created () {
    this.loadCountryItems()
    this.attribValues()
  },
  methods: {
    attribValues () {
      const values = this.$clone(this.values)
      Object.keys(values).forEach(key => {
        if (key === 'date' && typeof values[key] === 'string') values[key] = values[key].slice(0,10)
        this[key] = values[key]
      })
    },

    loadCountryItems () {
      this.loading = true
      this.$services.api_user.country
        .search({where: {status: [1]}})
        .then(response => {
          if (response.data) {
            this.countryItems = response.data
          } else {
            this.countryItems = []
          }
        })
        .finally(() => {
          this.loading = false
        })
    },


  }
}
</script>
